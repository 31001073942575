import request from '@/utils/request'
// 编辑
export function editConfig(data) {
  return request('post', '/apm/points/config/update', data)
}
// 查询积分配置
export function getConfig(data) {
    return request("get", "/apm/points/config/findById", data);
  }
  // 平台批量发放积分
export function batchPoint(data) {
  return request('post', '/apm/ums/shop/user/batch/give/point', data)
}
